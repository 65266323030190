import React from 'react'

import { alpha, Button, Grow } from '@mui/material'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { css, useTheme } from '@emotion/react'

import { useIsInView } from '../../utility/useIsInView'
import { ParallaxQueue, useParallaxQueue } from '../../utility/ParallaxQueue'
import { CategoryHeader } from './CategoryHeader'
import { CategoryLine } from './CategoryLine'
import { Event } from './Event'
import * as styles from './styles'
import { TimelineCategory, TimelineEvent } from './types'
import { YearLabel } from './YearLabel'
import { YearLine } from './YearLine'
import { useRect } from '../../utility/useRect'
import { StickyHeader } from './StickyHeader'
import { useDeepMemo } from '../../utility/useDeepMemo'

interface CellDefinition {
  type: 'yearLine' | 'yearLabel' | 'event'
  event?: TimelineEvent
  year?: number
  rowStart: number
  rowEnd: number
  columnStart?: number
}

function renderCell(
  cell: CellDefinition,
  yearParallaxQueue: ParallaxQueue,
  eventParallaxQueue: ParallaxQueue,
) {
  switch (cell.type) {
    case 'yearLine':
      return (
        <YearLine
          parallaxQueue={yearParallaxQueue}
          key={`yearLine-${cell.year}`}
          css={css`
            grid-row-start: ${cell.rowStart};
            grid-row-end: ${cell.rowEnd};
          `}
        />
      )

    case 'yearLabel':
      return cell.year === undefined ? null : (
        <YearLabel
          year={cell.year}
          parallaxQueue={yearParallaxQueue}
          key={`yearLabel-${cell.year}`}
          css={css`
            grid-row-start: ${cell.rowStart};
          `}
        />
      )

    case 'event':
      return cell.event === undefined ? null : (
        <Event
          event={cell.event}
          parallaxQueue={eventParallaxQueue}
          key={`event-${cell.rowStart}`}
          css={css`
            grid-row-start: ${cell.rowStart};
            grid-row-end: ${cell.rowEnd};
            grid-column-start: ${cell.columnStart};
          `}
        />
      )
  }
}

export const Timeline = ({
  categories,
  events,
}: {
  categories: TimelineCategory[]
  events: TimelineEvent[]
}) => {
  const [sortIsDesc, setSortIsDesc] = React.useState(true)
  const [sortIsFlipping, setSortIsFlipping] = React.useState(false)
  const [headerRef, headerRect] = useRect()
  const theme = useTheme()
  // allow room for header
  const rootMargin = `${headerRect ? -(headerRect.height + parseInt(theme.spacing(2))) : 0}px 0px 0px 0px`
  const yearParallaxQueue = useParallaxQueue({
    name: 'year',
    observerProps: { threshold: 1, rootMargin },
  })
  const eventParallaxQueue = useParallaxQueue({
    name: 'event',
    autoStart: false,
    observerProps: {
      threshold: 1,
      rootMargin,
    },
  })

  React.useEffect(() => {
    if (sortIsFlipping) {
      ;(async () => {
        yearParallaxQueue.setEasingEnabled(false)
        eventParallaxQueue.setEasingEnabled(false)
        await Promise.all([
          new Promise<void>((resolve) => yearParallaxQueue.forceHideAll(resolve)),
          new Promise<void>((resolve) => eventParallaxQueue.forceHideAll(resolve)),
        ])
        yearParallaxQueue.disconnect()
        eventParallaxQueue.disconnect()
        yearParallaxQueue.setEasingEnabled(true)
        eventParallaxQueue.setEasingEnabled(true)
        setSortIsFlipping(false)
        setSortIsDesc(!sortIsDesc)
        // Wait for state to take, then reinit observers
        setTimeout(() => {
          yearParallaxQueue.reInitObserver()
          eventParallaxQueue.reInitObserver()
        })
      })()
    }
  }, [sortIsFlipping, sortIsDesc, eventParallaxQueue, yearParallaxQueue])

  const [timelineInView, timelineRef] = useIsInView({
    threshold: 0,
    // Add negative margin to avoid showing the sticky header when the timeline is barely visible but the regular header is not
    rootMargin: '-100px 0px -100px 0px',
  })
  React.useEffect(() => {
    // Since `autoStart` is false, start the queue when the timeline is in view
    if (timelineInView) {
      eventParallaxQueue.resume()
    }
  }, [timelineInView, eventParallaxQueue])

  const cells = useDeepMemo(() => {
    const c: React.ReactElement[] = []

    // future year line
    c.push(
      <YearLine
        fadeIn
        enabled={!sortIsDesc}
        parallaxQueue={yearParallaxQueue}
        css={css`
          grid-row-start: 3;
          grid-row-end: 4;
        `}
        key="yearLine-asc-future"
      />,
    )

    // space between category headers and events
    c.push(<div css={styles.topBufferCell} key="topBuffer" />)

    const startRow = 3
    let rowIndex = startRow
    let lastRow: number | null = null

    const eventCells: Array<CellDefinition> = (() => {
      const eventCells: CellDefinition[] = []
      let lastYear: number | null = null
      for (let eventIndex = 0; eventIndex <= events.length; eventIndex++) {
        const event = events[eventIndex]
        const rowStartIndex = rowIndex

        const currentYear = event ? event.start.getFullYear() : new Date().getFullYear()
        if (lastYear !== currentYear) {
          if (lastRow !== null) {
            // year line
            eventCells.push({
              type: 'yearLine',
              year: currentYear,
              rowStart: lastRow + 1,
              rowEnd: rowIndex + 1,
            })
          }
          rowIndex++

          lastRow = rowIndex
          lastYear = currentYear
          // year label
          eventCells.push({
            type: 'yearLabel',
            year: currentYear,
            rowStart: rowIndex++,
            rowEnd: rowIndex,
          })
        }

        // event
        if (event) {
          const categoryIndex = categories.indexOf(event.category)
          eventCells.push({
            type: 'event',
            event,
            rowStart: rowStartIndex,
            rowEnd: rowIndex++,
            columnStart: 2 + categoryIndex,
          })
        }
      }
      return eventCells
    })()

    // future year line
    c.push(
      <YearLine
        fadeOut
        enabled={sortIsDesc}
        parallaxQueue={yearParallaxQueue}
        css={css`
          grid-row-start: ${lastRow! + 1};
          grid-row-end: ${rowIndex++ + 1};
        `}
        key="yearLine-desc-future"
      />,
    )

    // render cells
    if (sortIsDesc) {
      eventCells.forEach((cell) =>
        c.push(renderCell(cell, yearParallaxQueue, eventParallaxQueue)!),
      )
    } else {
      for (let cellIndex = eventCells.length - 1; cellIndex >= 0; cellIndex--) {
        const cellDefinition = eventCells[cellIndex]
        const rowStart = rowIndex - cellDefinition.rowEnd + startRow
        cellDefinition.rowEnd = rowIndex - cellDefinition.rowStart + startRow
        cellDefinition.rowStart = rowStart
        c.push(
          renderCell(
            cellDefinition,
            yearParallaxQueue,
            eventParallaxQueue,
          )!,
        )
      }
    }

    // sort button
    c.push(
      <StickyHeader
        css={styles.stickySortCell}
        stickyHeaderEnabled={timelineInView}
        key={`sort-button-${timelineInView}`}
      >
        {() => (
          <Button onClick={() => setSortIsFlipping(true)}>
            {sortIsDesc ? <ArrowDownward /> : <ArrowUpward />}
          </Button>
        )}
      </StickyHeader>,
    )

    categories.forEach((category, categoryIndex) => {
      const gridColumnStart = 2 + categoryIndex

      // category header
      c.push(
        <CategoryHeader
          category={category}
          stickyHeaderEnabled={timelineInView}
          ref={headerRef}
          key={`categoryHeader-${category.name}-${timelineInView}`}
          css={css`
            grid-column-start: ${gridColumnStart};
            grid-row-start: 1;
          `}
        />,
      )

      // category line
      c.push(
        <Grow
          in={timelineInView}
          timeout={styles.CATEGORY_LINE_ANIMATION_DURATION_MS}
          key={`categoryLine-${category.name}-${timelineInView}`}
        >
          <CategoryLine
            color={alpha(category.color, 0.5)}
            css={css`
              grid-column-start: ${gridColumnStart};
              grid-row-start: 2;
              grid-row-end: ${rowIndex};
            `}
          />
        </Grow>,
      )
    })
    return c
  }, [
    categories,
    events,
    sortIsDesc,
    yearParallaxQueue,
    eventParallaxQueue,
    timelineInView,
  ])

  return (
    <div
      css={[
        styles.container,
        css`
          grid-template-columns: min-content repeat(${categories.length + 2}, minmax(min-content, 150px));
        `,
      ]}
      ref={timelineRef}
    >
      {cells}
    </div>
  )
}
