import { createTheme, css } from '@mui/material/styles'
import type { Theme as MuiTheme, Shadows, Theme } from '@mui/material/styles'

import { entries, fromEntries } from '@benkrejci/shared/src/utility'
import { config } from '../utility/config'
import { ApiRichText } from '@benkrejci/shared/src/api/api'
import { staticAssertNever } from '@benkrejci/shared/src/utilityTypes'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: PaletteColor
    quarternary: PaletteColor
  }

  interface PaletteOptions {
    tertiary?: PaletteColorOptions
    quarternary?: PaletteColorOptions
  }

  interface TypeBackground {
    paperSecondary?: string
  }
}

declare module '@mui/material/styles/createTheme' {
  interface TypeGradient {
    groovy: string
    subdued: string
  }

  interface Theme {
    gradient: TypeGradient
  }

  interface ThemeOptions {
    gradient?: Partial<TypeGradient>
  }
}

declare module '@emotion/react' {
  export interface Theme extends MuiTheme {}
}

const themeOptions = config.front.theme

const contentFontFamily =
  themeOptions.googleFonts.content?.family ?? themeOptions.googleFonts.primary.family

const getHeaderSizeProps = ({
  sizeRem,
  lineHeight,
}: {
  sizeRem: number
  lineHeight: number
}) => ({
  fontSize: `${sizeRem}rem`,
  lineHeight: lineHeight,
  marginBottom: '0.2em',
})

const getHeaderOverrides = ({ lineHeight }: { sizeRem: number; lineHeight: number }) => ({
  // Cancel out the extra line height on top and bottom
  '&:first-child': {
    marginTop: `${(-(lineHeight - 1) / 1).toFixed(2)}em`,
  },
  '&:last-child': {
    marginBottom: `${(-(lineHeight - 1) / 1).toFixed(2)}em`,
  },
})

const HEADER_FONT_SIZES = fromEntries(
  entries({
    h1: { sizeRem: 4, lineHeight: 1 },
    h2: 2.5,
    h3: 2.2,
    h4: 1.7,
    h5: 1.3,
    subtitle1: 1.2,
    subtitle2: 1.2,
  }).map(([key, value]) => [
    key,
    typeof value === 'number' ? { sizeRem: value, lineHeight: 1.2 } : value,
  ]),
)

const breakpoints = createTheme().breakpoints

export const theme = createTheme({
    typography: {
      fontFamily: themeOptions.googleFonts.primary.family,
      h1: {
        textTransform: 'uppercase',
        fontWeight: 500,
        letterSpacing: '0.2em',
        fontSize: `${HEADER_FONT_SIZES.h1.sizeRem}rem`,
        lineHeight: HEADER_FONT_SIZES.h1.lineHeight,
        marginTop: '-0.08em',
        marginBottom: '-0.08em',
      },
      h2: {
        fontFamily: contentFontFamily,
        ...getHeaderSizeProps(HEADER_FONT_SIZES.h2),
      },
      h3: {
        fontFamily: contentFontFamily,
        ...getHeaderSizeProps(HEADER_FONT_SIZES.h3),
      },
      h4: {
        fontFamily: contentFontFamily,
        ...getHeaderSizeProps(HEADER_FONT_SIZES.h4),
      },
      h5: {
        fontFamily: contentFontFamily,
        ...getHeaderSizeProps(HEADER_FONT_SIZES.h5),
      },
      subtitle1: {
        ...getHeaderSizeProps(HEADER_FONT_SIZES.subtitle1),
      },
      subtitle2: {
        fontFamily: contentFontFamily,
        ...getHeaderSizeProps(HEADER_FONT_SIZES.subtitle2),
      },
      body1: {
        fontFamily: themeOptions.googleFonts.primary
        .family,
        fontSize: '1rem',
      },
      body2: {
        fontFamily: contentFontFamily,
        fontSize: '1rem',
        lineHeight: 1.8,
      },
    },

    palette: {
      mode: themeOptions.mode,
      primary: {
        main: themeOptions.primary,
      },
      secondary: {
        main: themeOptions.secondary,
      },
      tertiary: {
        main: themeOptions.tertiary ?? themeOptions.primary,
      },
      quarternary: {
        main: themeOptions.quarternary ?? themeOptions.secondary,
      },
      text: {
        secondary: themeOptions.text,
      },
      background: {
        default: themeOptions.backgroundPrimary,
        paper: themeOptions.backgroundPaper,
        paperSecondary: themeOptions.backgroundPaperSecondary,
      },
      tonalOffset: 0.2,
    },

    gradient: {
      groovy: `linear-gradient(50deg, ${themeOptions.primary} 0%, ${themeOptions.secondary} 100%)`,
      subdued: `linear-gradient(130deg, ${themeOptions.secondary} 0%, ${themeOptions.primary} 100%)`,
    },

    shape: {
      borderRadius: 13,
    },

    shadows: [
      'none',
      ...new Array(24)
        .fill('')
        .map(
          (_, index) =>
            `0px ${Math.round(index / 3 + 2)}px ${Math.round(index * 1.5 + 10)}px 0px rgba(0, 0, 0, ${themeOptions.shadowOpacity})`,
        ),
    ] as Shadows,

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          // Set the 
          html: {
            fontSize: '92%',
            [breakpoints.up('sm')]: {
              fontSize: '100%',
            },
            [breakpoints.up('md')]: {
              fontSize: '104%',
            },
            [breakpoints.up('lg')]: {
              fontSize: '108%',
            },
          },
          body: {
            background: themeOptions.backgroundBody ?? themeOptions.backgroundPrimary,
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: '0.5em',
          },

          // h1: getHeaderOverrides(HEADER_FONT_SIZES.h1),
          h2: getHeaderOverrides(HEADER_FONT_SIZES.h2),
          h3: getHeaderOverrides(HEADER_FONT_SIZES.h3),
          h4: getHeaderOverrides(HEADER_FONT_SIZES.h4),
          h5: getHeaderOverrides(HEADER_FONT_SIZES.h5),
          subtitle1: getHeaderOverrides(HEADER_FONT_SIZES.subtitle1),
          subtitle2: getHeaderOverrides(HEADER_FONT_SIZES.subtitle2),
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            // Arbitrarily large radius to make the sides circular
            borderRadius: '100px',
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
            backgroundColor: themeOptions.backgroundPaper,
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: themeOptions.backgroundPaper,
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            backgroundColor: themeOptions.backgroundPaper,
          },
        },
      },
    },
  })

export const getFormatCss = (format: ApiRichText['format'], theme: Theme) =>
  format == null
    ? null
    : entries(format).map(([name, value]) => {
        if (value == null) return null
        switch (name) {
          case 'id':
            return null
          case 'fontSizeRem':
            return css`
              font-size: ${value}rem;
            `
          case 'font':
            return css`
              font-family: ${value === 'primary'
                ? theme.typography.fontFamily
                : theme.typography.body2.fontFamily};
            `
          case 'textColor':
            return value in theme.palette
              ? css`
                  color: ${theme.palette[value as 'primary' | 'secondary' | 'tertiary']
                    .main};
                `
              : ''
          case 'opacity':
            return css`
              opacity: ${value};
            `
          case 'marginBottom':
            return css`
              margin-bottom: ${theme.spacing(value)};
            `
          case 'marginTop':
            return css`
              margin-top: ${theme.spacing(value)};
            `
          case 'marginLeft':
            return css`
              margin-left: ${theme.spacing(value)};
            `
          case 'marginRight':
            return css`
              margin-right: ${theme.spacing(value)};
            `
          default:
            return staticAssertNever(name)
        }
      })
