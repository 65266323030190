import { css } from '@emotion/react'

import {
  Grid,
  Typography,
} from '@mui/material'

import { Icon } from '../../icons/Icon'
import { ApiSocial } from '@benkrejci/shared/dist/api/api'
import * as globalStyles from '../../style/global'
import { Link } from '../../utility/Link'

const socialTitlesByType: { [type: string]: string } = {
  linkedin: 'LinkedIn',
  twitter: 'Twitter',
  soundcloud: 'SoundCloud',
  instagram: 'Instagram',
  github: 'GitHub',
  thangs: 'Thangs',
  facebook: 'Facebook',
}

// The original sizes are hard-coded here: https://github.com/mui/material-ui/blob/0eb2ba11ab860f60c1444b26490f2520dd9c0e2c/packages/mui-material/src/Icon/Icon.js#L57-L79
// to be 20, 24, 36, but that doesn't work super well for socials so we're using
// these instead
const ICON_REM_BY_SIZE = {
  small: 1.6,
  medium: 1.8,
  large: 2,
}

export const Socials = ({
  socials,
  wide,
  size = 'large',
}: {
  socials: ApiSocial[]
  wide?: boolean
  size?: 'inherit' | 'small' | 'large'
}) => (
  <Grid container spacing={1}>
    {socials.map((social, i) => {
      if (!(social.type in socialTitlesByType)) {
        console?.warn && console.warn(`Unknown social type: ${social.type}`)
        return null
      }
      const title = socialTitlesByType[social.type]
      return (
        <Grid item key={social.type + i} xs={wide ? 12 : undefined}>
          <Link href={social.url} css={globalStyles.linkNoDecoration}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              wrap="nowrap"
            >
              <Grid item xs={wide ? false : 12}>
                <Icon
                  name={social.type}
                  aria-label={wide ? '' : title}
                  css={[
                    (theme) => css`
                      display: block;
                      color: ${theme.palette.primary.main};
                    `,
                    wide
                      ? css`
                          font-size: 2rem;
                        `
                      : css`
                          font-size: ${size === 'inherit'
                            ? size
                            : ICON_REM_BY_SIZE[size]}rem;
                        `,
                  ]}
                />
              </Grid>
              {wide ? (
                <Grid item>
                  <Typography
                    color="textPrimary"
                    css={[
                      (theme) => css`
                        font-weight: 500;
                        color: ${theme.palette.primary.main};
                      `,
                    ]}
                  >
                    {title}
                  </Typography>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Link>
        </Grid>
      )
    })}
  </Grid>
)
