const idRe = /^(?:.+:|)(\d+)$/
const TITLE_SLUG_MAX_LENGTH = 32

export const getPageUrl = ({ slug }: { slug: string }) =>
  `/${slug === 'home' ? '' : encodeURIComponent(slug)}`

/**
 * idOrSlug for post with id=12 and slug=a_slug can be:
 * - 12
 * - a_slug
 * - from_title:12
 */
export const parseIdOrSlug = (
  idOrSlug: string,
): { id: number; slug: undefined } | { id: undefined; slug: string } => {
  const idMatch = idOrSlug.match(idRe)
  if (idMatch) {
    return { id: parseInt(idMatch[1]), slug: undefined }
  }
  return { id: undefined, slug: idOrSlug }
}

interface PostProps {
  id: number
  title: string
  slug?: string | undefined
}

export const getIdOrSlug = (post: PostProps) => {
  if (post.slug != null) {
    return post.slug
  }
  return `${post.title.replace(/[^a-zA-Z0-9_-]+/g, '-').slice(0, TITLE_SLUG_MAX_LENGTH)}:${post.id}`
}

export const getPostUrl = (post: PostProps) => `/post/${getIdOrSlug(post)}`
