import { ReactElement } from 'react'

import { Box, css, Grow, Paper, Typography } from '@mui/material'

import { Icon } from '../../icons/Icon'
import * as globalStyles from '../../style/global'
import { ParallaxQueue } from '../../utility/ParallaxQueue'
import { QueuedParallaxShow } from '../../utility/QueuedParallaxShow'
import { WrapIf } from '../../utility/WrapIf'
import { RichText } from '../widgets/RichText'
import * as styles from './styles'
import { TimelineEvent } from './types'
import { Link } from '../../utility/Link'

export const Event = ({
  enabled,
  event,
  parallaxQueue,
  className,
}: {
  enabled?: boolean
  event: TimelineEvent
  parallaxQueue: ParallaxQueue
  className?: string
}): ReactElement => (
  <div css={styles.eventCell} className={className}>
    <WrapIf
      if={event.url}
      wrap={(children, ref) => (
        <Link href={event.url!} ref={ref}>
          {children}
        </Link>
      )}
    >
      {(ref) => (
        <QueuedParallaxShow
          enabled={enabled}
          queue={parallaxQueue}
          Transition={(props) => (
            <Grow timeout={styles.EVENT_ANIMATION_DURATION_MS} {...props} />
          )}
          ref={ref}
        >
          <Paper elevation={2}>
            <Icon name={event.icon} css={styles.eventIcon} />
            <Typography variant="h5" component="h4" css={css`
            margin: 0;
            `}>
              {event.title}
            </Typography>
            <Box css={globalStyles.visuallyHidden}>Category: {event.category.name}</Box>
            {event.description ? <RichText css={css`
            line-height: 1.2;
            `}>{event.description}</RichText> : null}
          </Paper>
        </QueuedParallaxShow>
      )}
    </WrapIf>
  </div>
)
