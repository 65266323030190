import React from 'react'

import { Helmet } from 'react-helmet-async'

import { ApiSite, ApiPage, ApiFile } from '@benkrejci/shared/dist/api/api'
import { Navigation } from './Navigation'
import { Socials } from './widgets/Socials'
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  HiddenProps,
  IconButton,
  Typography,
} from '@mui/material'
import { Widgets } from './widgets/Widgets'
import { Theme } from '@emotion/react'
import { css } from '@emotion/react'
import { ArrowBack } from '@mui/icons-material'
import { getButtonLinkProps, Link } from '../utility/Link'
import { getResponsiveSpace, getResponsiveSpaceCss } from '../style/global'
import { LoadingBox } from '../utility/LoadingBox'
import { config } from '../utility/config'

const getHiddenProps = (props: string, direction: 'Up' | 'Down') => {
  const hiddenProps: HiddenProps = {}
  if (props) {
    const propsArray = props.split(' ') as ('xs' | 'sm' | 'md' | 'lg' | 'xl')[]
    for (const prop of propsArray) {
      hiddenProps[`${prop}${direction}`] = true
    }
  }
  return hiddenProps
}

const containerCss = (theme: Theme) => css`
  // Setting overflow to force inner margin not to collapse outside the container.
  // Otherwise margin will end up between <html> and container elements
  overflow: hidden;
  min-height: 100vh;

  display: grid;
  grid-template-rows: min-content auto;

  ${getResponsiveSpaceCss(theme, [
    'padding-left',
    'padding-right',
    { type: 'padding-bottom', xsValue: 1 },
  ])}

  & h1 {
    background: ${theme.gradient.subdued};
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const Page = ({
  isLoading,
  slug,
  backButton,
  site,
  page,
  children,
}: {
  isLoading: boolean
  slug: string | undefined
  backButton?: { label?: string; href: string }
  site?: ApiSite
  page?: Omit<ApiPage, 'slug' | 'file'> & {
    slug: string | undefined
    file: ApiFile | undefined
  }
  children?: React.ReactNode
}) => {
  const title = site?.title ?? config.front.defaultTitle
  const header = ''
  const footer = ''

  let socialHiddenProps: HiddenProps = {}
  if (page?.hideSocialDown)
    socialHiddenProps = {
      ...socialHiddenProps,
      ...getHiddenProps(page.hideSocialDown, 'Down'),
    }
  if (page?.hideSocialUp)
    socialHiddenProps = {
      ...socialHiddenProps,
      ...getHiddenProps(page.hideSocialUp, 'Up'),
    }

  return (
    <>
      <Helmet>
        <title>
          {page?.title ? `${page.title} - ` : ''}
          {site?.title ?? ''}
        </title>
        {page?.description ? <meta name="description" content={page?.description} /> : ''}
      </Helmet>

      <Container maxWidth={page?.fullBleed ? false : 'lg'} css={containerCss}>
        <Box my={getResponsiveSpace()} component="header">
          <Grid
            container
            spacing={getResponsiveSpace()}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Link href="/" style={{ textDecoration: 'none' }}>
                <Typography variant="h1">
                  <LoadingBox isLoading={title === undefined} content={title} />
                </Typography>
              </Link>
            </Grid>

            {page?.hideSocial ||
            (site !== undefined && site.socials === undefined) ? null : (
              <Grid item>
                <Hidden implementation="css" {...socialHiddenProps}>
                  <LoadingBox isLoading={isLoading} minHeight="42px">
                    {(content) =>
                      site?.socials !== undefined ? (
                        <Socials socials={site?.socials} />
                      ) : (
                        content
                      )
                    }
                  </LoadingBox>
                </Hidden>
              </Grid>
            )}

            <Grid item>
              <Box>
                <LoadingBox isLoading={isLoading} minHeight="55px" minWidth="180px">
                  {(content) =>
                    site?.navTopPages !== undefined ? (
                      <Navigation slug={slug} pages={site.navTopPages} />
                    ) : (
                      content
                    )
                  }
                </LoadingBox>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {header}

        <div
          css={(theme) => css`
            display: grid;
            align-items: start;
            grid-template-rows: 1fr auto;
            grid-template-columns: [first-start last-start main-start] 1fr [main-end first-end last-end];

            ${getResponsiveSpaceCss(theme, ['grid-gap'])}

            ${theme.breakpoints.up('sm')} {
              grid-template-columns: ${page?.widgetsLeft?.length
                  ? '[first-start] minmax(min-content, 25%) '
                  : ''} [first-end main-start] 1fr [main-end last-start] ${page
                  ?.widgetsRight?.length
                  ? 'minmax(min-content, 25%) [last-end]'
                  : ''};
            }
          `}
        >
          {site && page?.widgetsLeft?.length ? (
            <Widgets
              site={site}
              css={css`
                grid-column: first-start / first-end;
              `}
            >
              {page.widgetsLeft}
            </Widgets>
          ) : null}

          <div
            css={css`
              grid-column: main-start / main-end;
            `}
          >
            {backButton === undefined ? null : (
              <Grid
                mb={getResponsiveSpace()}
                ml={-1}
                css={css`
                  grid-column: main-start / main-end;
                `}
              >
                {backButton.label === undefined ? (
                  <IconButton {...getButtonLinkProps(backButton.href)}>
                    <ArrowBack />
                  </IconButton>
                ) : (
                  <Button
                    {...getButtonLinkProps(backButton.href)}
                    variant="text"
                    size="large"
                    startIcon={<ArrowBack />}
                  >
                    {backButton.label}
                  </Button>
                )}
              </Grid>
            )}

            {site && page?.widgetsMain ? (
              <Widgets site={site}>{page.widgetsMain}</Widgets>
            ) : isLoading ? (
              <LoadingBox
                isLoading
                fill="text"
                numTextParagraphs={2}
                numTextRows={3}
                numTextCols={72}
              />
            ) : null}

            {children}
          </div>

          {site && page?.widgetsRight?.length ? (
            <Widgets
              site={site}
              css={css`
                grid-column: last-start / last-end;
              `}
            >
              {page.widgetsRight}
            </Widgets>
          ) : null}

          {site?.globalFooterWidgets ? (
            <Widgets
              site={site}
              css={css`
                grid-column: main-start / main-end;
                text-align: center;
              `}
            >
              {site.globalFooterWidgets}
            </Widgets>
          ) : (
            ''
          )}
        </div>

        {footer}
      </Container>
    </>
  )
}
