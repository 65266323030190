import { useCachedFetchSingle } from '../../utility/api/useCachedFetch'
import { getPosts, PostSorts } from '@benkrejci/shared/dist/api/api'

export const dateSort: PostSorts = [
  {
    field: 'date',
    isAsc: false,
  },
  { field: 'createdAt', isAsc: false },
]

export const usePosts = (doFetch: boolean, sort: PostSorts = dateSort) =>
  useCachedFetchSingle('posts', 'query', '*', {
    load: () => getPosts(sort),
    getErrorMessage: () => 'Oh no! There was a problem loading posts 😭',
    doLoad: doFetch,
  })
