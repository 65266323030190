import { staticAssertNever } from '@benkrejci/shared/src/utilityTypes'
import { css, useTheme } from '@emotion/react'
import { Breakpoint, Theme, useMediaQuery } from '@mui/material'

/**
 * taken from https://material-ui.com/components/use-media-query/#migrating-from-withwidth
 *
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
type BreakpointOrNull = Breakpoint | null

export const useCurrentBreakpoint = (): Breakpoint => {
  const theme: Theme = useTheme()
  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys]
  console.log(keys)
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return matches ? key : output
    }, null) ?? 'xs'
  )
}

const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'] as const
const contentBreakpoints = ['xs', 'sm', 'md'] as const
// Only use breakpoints smaller than the max content width
type ContentBreakpoint = (typeof contentBreakpoints)[number]
const SPACE_BY_BREAKPOINT_CONTENT: Record<ContentBreakpoint, number> = {
  xs: 2,
  sm: 2.5,
  md: 3,
}
const SPACE_BY_BREAKPOINT: Record<Breakpoint, number> = {
  ...SPACE_BY_BREAKPOINT_CONTENT,
  lg: 3.5,
  xl: 4,
}

const clampContentBreakpoint = (breakpoint: Breakpoint): ContentBreakpoint =>
  contentBreakpoints.find((bp) => bp === breakpoint) ?? 'md'

// Content only goes up to md
export const useCurrentContentBreakpoint = (): ContentBreakpoint => {
  const breakpoint = useCurrentBreakpoint()
  return clampContentBreakpoint(breakpoint)
}

const getResponsiveSpaceForBreakpoint = (
  breakpoint: Breakpoint,
  xsValue = SPACE_BY_BREAKPOINT_CONTENT.xs,
) => SPACE_BY_BREAKPOINT[breakpoint] * (xsValue / SPACE_BY_BREAKPOINT.xs)

export const getResponsiveSpace = (
  xsValue = SPACE_BY_BREAKPOINT_CONTENT.xs,
  fullWidth = false,
) =>
  (fullWidth ? breakpoints : contentBreakpoints).reduce(
    (obj, name) =>
      ({
        ...obj,
        [name]: SPACE_BY_BREAKPOINT[name] * (xsValue / SPACE_BY_BREAKPOINT_CONTENT.xs),
      }) as const,
    {} as Record<(typeof contentBreakpoints)[number], number>,
  )

export const useResponsiveSpace = (
  xsValue = SPACE_BY_BREAKPOINT_CONTENT.xs,
  fullWidth = false,
) => {
  const theme = useTheme()
  const breakpoint = useCurrentBreakpoint()
  return theme.spacing(
    getResponsiveSpaceForBreakpoint(
      fullWidth ? breakpoint : clampContentBreakpoint(breakpoint),
      xsValue,
    ),
  )
}

type SpaceProp =
  | string
  | {
      type: string
      xsValue?: number
    }

export const getResponsiveSpaceCss = (
  theme: Theme,
  props: SpaceProp | SpaceProp[],
  fullWidth = false,
) => {
  const innerProps = (Array.isArray(props) ? props : [props]).map((prop) =>
    typeof prop === 'string' ? { type: prop } : prop,
  )
  return css`
    ${(fullWidth ? breakpoints : contentBreakpoints).map(
      (size) => css`
        ${theme.breakpoints.up(size)} {
          ${innerProps.map(
            ({ type, xsValue }) => css`
              ${type}: ${theme.spacing(getResponsiveSpaceForBreakpoint(size, xsValue))};
            `,
          )}
        }
      `,
    )}
  `
}

export const getGridPropsFromSize = (size: 'small' | 'medium' | 'large') =>
  size === 'small'
    ? { xs: 6, sm: 4, md: 3 }
    : size === 'medium'
      ? { xs: 12, sm: 6, md: 4 }
      : size === 'large'
        ? { xs: 12, sm: 12, md: 6 }
        : staticAssertNever(size)

export const linkNoDecoration = (theme: Theme) => css`
  color: ${theme.palette.text.primary};
  text-decoration: none;
`

export const inlineIcon = css`
  position: relative;
  top: -0.08em;
  vertical-align: middle;
  --icon-size: 1em;
  h1 &,
  h2 &,
  h3 &,
  h4 &,
  h5 & {
    --icon-size: 1.2rem;
  }
  font-size: var(--icon-size);
  a:hover & {
    text-decoration: underline;
  }
`

export const headingLinkWithTag = [
  linkNoDecoration,
  css`
    display: flex;
    align-items: center;
  `,
]

export const headingTagIcon = [
  inlineIcon,
  (theme: Theme) => css`
    color: ${theme.palette.primary.main};
    margin-left: calc(0px - var(--icon-size));
  `,
]

// TODO: this is in @material-ui/utils v5.0 so get rid of it when we upgrade
export const visuallyHidden = (theme: Theme) => css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: ${theme.spacing(-1)};
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
