import { ApiFile } from '@benkrejci/shared/src/api/api'
import { Link } from '../../../utility/Link'
import { css } from '@emotion/react'
import { Media } from '../../Media'

export type GalleryItem = ApiFile & {
  viewUrl: string
}

export const GalleryChild = ({
  item,
  heightPx,
  widthPx,
  className,
}: {
  item: GalleryItem
  heightPx?: number
  widthPx?: number
  className?: string
}) => (
  <Link href={item.viewUrl} preventScrollReset className={className}>
    <Media
      file={item}
      showFormat="large"
      height={heightPx}
      width={widthPx}
      maxHeight={null}
      css={css`
        border-radius: 0;
      `}
    />
  </Link>
)
