import { css } from '@emotion/react'
import { Theme } from '@mui/material'
import React from 'react'

export const LoadingBox = ({
  contentCss,
  numTextCols = 10,
  numTextRows = 1,
  numTextParagraphs = 1,
  fill = 'box',
  minWidth = '100px',
  minHeight = '24px',
  ...props
}: {
  contentCss?: ReturnType<typeof css>
  numTextCols?: number
  numTextRows?: number
  numTextParagraphs?: number
  fill?: 'box' | 'text'
  minWidth?: string
  minHeight?: string
  isLoading: boolean
  content?: React.ReactNode
  children?: (content?: React.ReactNode) => React.ReactNode
}) => {
  if (props.isLoading) {
    return (
      <PlaceholderBox
        contentCss={contentCss}
        numTextCols={numTextCols}
        numTextRows={numTextRows}
        numTextParagraphs={numTextParagraphs}
        fill={fill}
        minWidth={minWidth}
        minHeight={minHeight}
      >
        {(content) =>
          props.children && typeof props.children === 'function'
            ? props.children(content)
            : content
        }
      </PlaceholderBox>
    )
  } else {
    return typeof props.children === 'function'
      ? props.children(props.content)
      : props.children ?? props.content
  }
}

const filledCss = (theme: Theme) => css`
  background: ${theme.gradient.subdued};
  opacity: 0.2;

  animation: 2s infinite alternate pulse;
  @keyframes pulse {
    0% {
      opacity: 0;
    }

    40% {
      opacity: 0.2;
    }

    100% {
      opacity: 0.2;
    }
  }
`

const filledTextCss = (theme: Theme) => [
  css`
    border-radius: 0.5em;
    margin: 0.15em 0;
  `,
  filledCss,
]

const useStatic = <T,>(value: T) => React.useRef(value).current

const PlaceholderBox = ({
  contentCss,
  minWidth,
  minHeight,
  numTextCols,
  numTextRows,
  numTextParagraphs,
  fill,
  children,
}: {
  contentCss?: ReturnType<typeof css>
  minWidth: string
  minHeight: string
  numTextCols: number
  numTextRows: number
  numTextParagraphs: number
  fill: 'box' | 'text'
  children: (content: NonNullable<React.ReactNode>) => React.ReactNode
}) => {
  const rows = useStatic(
    new Array(numTextParagraphs).fill(0).map((_zero, paragraphIndex) => (
      <div key={paragraphIndex}>
        {new Array(numTextRows).fill(0).map((_zero, rowIndex) => (
          <div key={rowIndex}>
            <span
              css={[
                fill === 'text' ? filledTextCss : null,
                css`
                  display: inline-block;
                  width: ${fill === 'box'
                    ? `${numTextCols}ch`
                    : `${(0.7 * Math.random() + 0.3) * 100}%`};
                  /* padding: ${fill === 'box' ? '0.15em' : 0}; */
                `,
              ]}
            >
              &nbsp;
            </span>
          </div>
        ))}
      </div>
    )),
  )

  return (
    <div
      css={[
        (theme) => css`
          border-radius: ${theme.shape.borderRadius}px;
        `,
        fill === 'box' ? filledCss : null,
      ]}
    >
      {children(
        <div
          css={[
            css`
              display: block;
              min-width: ${minWidth};
              min-height: ${minHeight};
              display: flex;
              flex-direction: column;
              row-gap: ${fill === 'text' ? '1em' : 0};
            `,
            contentCss,
          ]}
        >
          {rows}
        </div>,
      )}
    </div>
  )
}
