// import { alpha, css, Interpolation, styled, Theme } from '@mui/material'
import { css } from '@emotion/react'
import { alpha, Theme } from '@mui/material'

export const YEAR_LABEL_ANIMATION_DURATION_MS = 600
export const YEAR_LINE_ANIMATION_DURATION_MS = 1400
export const EVENT_ANIMATION_DURATION_MS = 400
export const CATEGORY_LINE_ANIMATION_DURATION_MS = 400

export const EVENT_INTERSECTION_MARGIN = '-72px 0px 0px 0px'

export const categoryHeader = (theme: Theme) => css`
  grid-column-end: span 3;
  grid-row-end: span 1;
  text-align: center;
  & .MuiTypography-root {
    display: inline-block;
    margin-top: ${theme.spacing(-1)};
    padding-top: ${theme.spacing(1)};
    background-color: ${alpha(theme.palette.background.default, 0.85)};
    box-shadow: 0 0 18px 18px ${alpha(theme.palette.background.default, 0.85)};
  }
`

export const verticalLineCell = css`
  grid-column-start: 1;
  grid-column-end: span 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`

export const categoryLineCell = css`
  grid-column-end: span 3;
`
  
export const container = (theme: Theme) => css`
  display: grid;
  margin-right: -${theme.spacing(2)};
  gap: ${theme.spacing(1/2)};
  justify-content: center;
`

export const topBufferCell = css`
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  min-height: 20px;
`

export const dateCell = (theme: Theme) => css`
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-end: span 1;
  text-align: center;
  align-self: end;
  margin: ${theme.spacing(1 / 2)};
`

export const verticalLineContainer = css`
  flex-grow: 1;
`

export const verticalLine = (theme: Theme) => css`
  height: 100%;
  min-height: 2px;
  width: 2px;
  background-color: ${alpha(theme.palette.text.primary, 0.6)};
`

export const fadeOutLine = (theme: Theme) => css`
  min-height: 20px;
  background: linear-gradient(180deg, ${alpha(
    theme.palette.text.primary,
    0.6,
  )} 0%, transparent 100%);
`

export const fadeInLine = (theme: Theme) => css`
  min-height: 20px;
  background: linear-gradient(180deg, transparent 0%, ${alpha(
    theme.palette.text.primary,
    0.6,
  )} 100%);
`

export const verticalLineCap = css`
  height: 40px;
  flex-grow: 0;
`

export const stickyHeader = (theme: Theme) => css`
  position: fixed;
  top: ${theme.spacing(1)};
  z-index: 20;
`

export const sortCell = (theme: Theme) => css`
  grid-row-start: 1;
  grid-row-end: span 3;
  grid-column-start: 1;
  grid-column-end: span 1;
  justify-self: center;
  align-self: self-start;
`

export const stickySortCell = [sortCell, (theme: Theme) => css`
  background-color: ${alpha(theme.palette.background.default, 0.85)};
  box-shadow: 0 0 18px 18px ${alpha(theme.palette.background.default, 0.85)};
`]

export const eventCell = (theme: Theme) => css`
  grid-column-end: span 3;
  grid-row-end: span 1;
  text-align: center;
  z-index: 10;
  & .MuiPaper-root {
    display: inline-block;
    padding: ${theme.spacing(1, 2)};
    position: relative;
    overflow: visible;
  }
  & .MuiLink-root .MuiPaper-root, & .MuiLink-root .MuiSvgIcon-root {
    color: ${theme.palette.primary.main};
  }
`

export const eventIcon = (theme: Theme) => css`
  position: absolute;
  left: -30px;
  top: 50%;
  margin-top: -12px;
  color: ${alpha(theme.palette.text.primary, 0.7)};
`