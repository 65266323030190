import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export default function Hackaday(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 870 870" {...props}>
      <path d="m871,121c-2,9.06-3.23,18.38-6.12,27.15-18.33,55.53-77.45,86.9-133.79,71.21-3.52-.98-5.72-.26-8.37,2.09-27.14,24.13-54.41,48.13-81.64,72.15-1.23,1.09-2.5,2.13-4.3,3.67-17.23-36.28-40.72-67.29-72.88-91.92,11.08-9.83,21.9-19.44,32.73-29.03,16.71-14.8,33.38-29.66,50.19-44.34,2.54-2.22,3.43-4.38,3.08-7.71-7.13-68.48,48.14-126.16,117.08-122.08,7.2.43,14.33,1.99,22.91,3.23-23.92,21.34-46.6,41.59-69.67,62.18,25.87,28.77,51.56,57.34,77.66,86.38,23.45-20.75,46.72-41.33,70.53-62.4.94,4.91,1.76,9.17,2.57,13.43v16Z" />
      <path d="m1,103c.72-3.56,1.44-7.12,2.34-11.56,23.66,20.93,46.92,41.51,70.67,62.53,25.87-28.66,51.59-57.16,77.69-86.07-23.01-20.54-45.71-40.79-69.7-62.2,8.51-1.34,15.7-3.1,22.96-3.51,59.07-3.33,109.57,38.84,116.7,97.59,1,8.2.54,16.65.05,24.93-.21,3.48.74,5.49,3.18,7.63,26.26,23.14,52.44,46.36,78.63,69.56,1.24,1.1,2.43,2.25,4.07,3.77-15.73,12.29-30.2,25.67-41.69,41.64-11.42,15.87-21.55,32.67-32.35,49.22-12.66-11.24-26.1-23.18-39.54-35.13-15.2-13.51-30.44-26.96-45.52-40.59-2.3-2.08-4.29-2.35-7.12-1.56C75.93,237.7,9.78,193.13,1.87,125.34c-.13-1.13-.58-2.22-.87-3.34,0-6.33,0-12.67,0-19Z" />
      <path d="m1,674c.99-5.2,1.82-10.44,2.98-15.61,12.18-54.56,65.52-91.89,120.88-84.6,4.77.63,9.57,1.4,14.19,2.68,3.27.9,5.47.44,8.04-1.86,25.35-22.62,50.82-45.1,76.27-67.61,1.11-.98,2.26-1.91,3.98-3.36,14.52,37.63,35.9,70.32,65.61,98.1-1.71,1.59-3.21,3.03-4.77,4.41-21.34,18.86-42.64,37.76-64.08,56.5-2.58,2.25-3.36,4.45-2.97,7.73,7.21,60.42-34.34,114.72-94.32,123.39-2.61.38-5.21.81-7.82,1.22h-15c-7.81-1.53-15.63-3.06-24.51-4.8,24.85-22.08,48.58-43.17,72.5-64.43-25.91-28.72-51.53-57.13-77.37-85.78-23.83,21.12-47.44,42.04-71.46,63.33-.81-4.25-1.49-7.79-2.17-11.33,0-6,0-12,0-18Z" />
      <path d="m551.84,599.38c9.49,19.35,7.92,35.67-4.37,48.59-11.09,11.66-28.21,15.27-43.22,9.11-16.2-6.66-24.57-21.2-23.83-41.75h-6.26c1.41,14.13-2.63,26.33-13.66,35.65-7.84,6.63-17.05,9.47-27.31,9.02-21.91-.95-35.48-17.35-36.61-44.64h-4.29c-.28,3.72-.25,7.38-.89,10.93-2.97,16.59-12.36,28.04-28.65,32.53-15.67,4.32-29.54.02-40.07-12.48-10.98-13.03-12.78-27.85-5.92-43.43,1.37-3.11.84-4.51-1.61-6.52-48.95-40.02-76.23-92.1-84.11-154.33-7.63-60.17,4.32-116.43,37.83-167.32,29.42-44.69,69.51-75.8,122.01-88.41,59.93-14.39,114.86-1.78,163.38,35.87,47.55,36.89,74.66,86.42,84.8,145.34,8.87,51.52,2.33,101.19-19.94,148.58-14.46,30.77-34.8,57.04-61.3,78.46-1.93,1.56-3.87,3.11-5.98,4.81Zm-266.4-183.79c.18,2.83.41,5.65.54,8.48.91,20.04,9.34,35.76,26.5,46.56,8.37,5.27,13.19,3.1,14.09-6.5.08-.83.1-1.66.18-2.49.92-9.08,4.75-16.2,13.5-19.97,9.46-4.08,18.9-8.2,28.4-12.19,9.94-4.17,19.24-9.42,26.16-17.79,6.36-7.69,10.03-16.9,5.15-26.41-10.54-20.5-27.45-32.12-50.88-33.23-35.88-1.7-65.01,33.54-63.65,63.53Zm239.21-63.7c-16.93.98-32.71,5.06-43.79,19.05-4.43,5.6-8.99,12.02-10.58,18.75-2.54,10.76,2.66,20.17,11.38,26.46,7.47,5.38,15.87,9.56,24.14,13.72,6.96,3.5,14.24,6.42,21.52,9.21,12.09,4.63,17.45,13.45,17.69,26.14.13,6.86,4.44,9.73,10.36,6.52,13.43-7.28,24.16-17.23,27.49-32.99,1.3-6.17,2.01-12.48,2.8-18.75,4.02-31.66-25.66-69.54-61.02-68.12Zm-74.57,192.02c4.09-4.51,5.81-8.7,5.85-13.46.13-17.68-3.3-34.68-10.36-50.89-1.05-2.4-2.53-4.7-4.2-6.73-3.45-4.21-6.35-4.2-9.81-.05-1.47,1.77-2.77,3.77-3.76,5.85-7.68,16.12-11.75,33.15-12.23,50.99-.12,4.52.35,9.07,4.51,13.45,1.56-3.55,2.73-6.22,3.92-8.88,2.08-4.69,3.72-9.66,6.37-14.01,3.69-6.06,8.52-5.77,11.55.59,1.98,4.16,3.06,8.75,4.57,13.14,1.05,3.04,2.16,6.06,3.57,10.02Z" />
      <path d="m578.41,601.49c29.76-27.38,51.33-59.99,65.87-97.98,7.86,6.93,15.32,13.51,22.77,20.1,18.97,16.78,37.97,33.52,56.85,50.41,2.61,2.33,4.79,3.08,8.37,2.09,48.12-13.35,102.5,11.04,125.2,55.65,11.34,22.28,15.04,45.56,10.9,71.46-24.2-21.45-47.76-42.32-71.67-63.52-25.82,28.62-51.44,57.02-77.37,85.78,24,21.33,47.74,42.43,72,64-10.88,3.73-21.48,4.96-32.22,4.92-65.07-.25-115.96-56.95-109.17-121.77.54-5.13-.53-8.47-4.56-11.96-21.17-18.31-42.04-36.98-63-55.52-1.23-1.09-2.42-2.23-3.96-3.65Z" />
    </SvgIcon>
  )
}
