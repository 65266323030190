import { ReactElement } from 'react'

import { Fade, Slide, Typography } from '@mui/material'

import { ParallaxQueue } from '../../utility/ParallaxQueue'
import { QueuedParallaxShow } from '../../utility/QueuedParallaxShow'
import * as styles from './styles'

export const YearLabel = ({
  year,
  parallaxQueue,
  className,
}: {
  year: number
  parallaxQueue: ParallaxQueue
  className?: string
}): ReactElement => (
  <div css={styles.dateCell} className={className} aria-hidden>
    <QueuedParallaxShow
      Transition={({ children, ...props }) => (
        <Slide
          direction="right"
          timeout={styles.YEAR_LABEL_ANIMATION_DURATION_MS}
          {...props}
        >
          <div>
            <Fade timeout={styles.YEAR_LABEL_ANIMATION_DURATION_MS} {...props}>
              {children}
            </Fade>
          </div>
        </Slide>
      )}
      queue={parallaxQueue}
    >
      <Typography variant="subtitle1" component="h3">
        {year}
      </Typography>
    </QueuedParallaxShow>
  </div>
)
