import { forwardRef, ReactElement } from 'react'

import { Typography } from '@mui/material'
import { css } from '@emotion/react'

import { TimelineCategory } from './types'
import * as styles from './styles'
import { StickyHeader } from './StickyHeader'

export const CategoryHeader = forwardRef<
  HTMLElement,
  {
    category: TimelineCategory
    stickyHeaderEnabled?: boolean
    className?: string
  }
>(
  ({ category, stickyHeaderEnabled, className }, outerRef): ReactElement => (
    <StickyHeader
      stickyHeaderEnabled={stickyHeaderEnabled}
      css={[
        styles.categoryHeader,
        css`
          color: ${category.color};
        `,
      ]}
      className={className}
      ref={outerRef}
    >
      {() => (
        <Typography variant="h5" component="h2">
          {category.name}
        </Typography>
      )}
    </StickyHeader>
  ),
)
