import { css } from '@emotion/react'

import { ApiNavPage } from '@benkrejci/shared/dist/api/api'
import { Box, Button } from '@mui/material'
import { getPageUrl } from '@benkrejci/shared/dist/api/route'
import { getButtonLinkProps } from '../utility/Link'

// I like it when the space between each edge is always the same.
// Since an active button's edge is the actual border, while an inactive button's
// edge, visually, is the text itself, make the inactive buttons overlap
// to keep everything evenly spaced.
const yPadding = 16
const xPadding = 20
const xSpaceBetween = 20
const ySpaceBetween = 4

export function Navigation({
  slug,
  pages,
}: {
  slug: string | undefined
  pages: ApiNavPage[]
}) {
  let isBeforeActivePage = true

  return (
    <Box
      css={css`
        display: flex;
        flex-wrap: wrap;
        margin: ${-ySpaceBetween}px ${-xSpaceBetween / 2}px;
        padding: 0;
      `}
    >
      {pages.map((page) => {
        const { isActive, href } = (() => {
          if (page.slug) {
            return {
              isActive: slug?.startsWith(page.slug),
              href: getPageUrl(page),
            }
          } else {
            return {
              isActive: false,
              href:
                page.url ??
                page.file?.url ??
                (() => {
                  console.error(`Invalid page for nav; must have slug, url, or media`)
                  return ''
                })(),
            }
          }
        })()
        if (isActive) isBeforeActivePage = false
        const position = isActive ? undefined : isBeforeActivePage ? 'before' : 'after'
        return (
          <Box key={page.slug}>
            <Button
              {...getButtonLinkProps(href)}
              variant={isActive ? 'contained' : 'text'}
              size="large"
              disableElevation
              css={(theme) => css`
                color: ${isActive
                  ? theme.palette.getContrastText(theme.palette.primary.main)
                  : position === 'before'
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main};
                line-height: 1;
                // Distance from border to next button edge (halfway between the two)
                // should be xSpaceBetween / 2.
                margin: ${isActive
                  ? `${ySpaceBetween}px ${xSpaceBetween / 2}px`
                  : // Distance from edge of text to next button edge (halfway between the two)
                    // should be xSpaceBetween / 2. Subtract distance already traveled via
                    // padding. This will end up being negative (inactive buttons will overlap)
                    `${ySpaceBetween}px ${xSpaceBetween / 2 - xPadding}px`};
                padding: ${isActive
                  ? `${yPadding}px ${xPadding}px`
                  : `${yPadding}px ${xPadding}px`};
                background: ${isActive ? theme.gradient.groovy : ''};
              `}
            >
              {page.title}
            </Button>
          </Box>
        )
      })}
    </Box>
  )
}
