import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface InternalLinkProps<T> extends React.HTMLProps<HTMLAnchorElement> {
  href: string // Force href to be required
  state?: T
  children: ReactNode
}

export const InternalLink: (<T>(props: InternalLinkProps<T>) => React.ReactNode) = React.forwardRef<HTMLAnchorElement, InternalLinkProps<any>>(
  ({ href, ...props }, ref) => {
    return <Link {...{ to: href, ref, ...props }} />
  },
)
