import React from 'react'
import { useForkRef } from 'rooks'

import { useIsInView } from '../../utility/useIsInView'
import { useRect } from '../../utility/useRect'
import * as styles from './styles'

export const StickyHeader = React.forwardRef<
  HTMLElement,
  {
    children: () => React.ReactElement
    className?: string
    stickyHeaderEnabled?: boolean
    style?: React.CSSProperties
  }
>(
  ({ className, children, stickyHeaderEnabled, style }, outerRef): React.ReactElement => {
    // Disregard horizontal occlusion by adding arbitrarily large horizontal margin
    // (This actually does not work in Chrome at the time of writing due to a presumed bug)
    const [inView, inViewRef] = useIsInView({ threshold: 1, rootMargin: '0px 2000px' })
    // Need to also watch for window resize events, because we are absolutely positioning the
    // sticky header (position: fixed). Changes to the position relative to the edge of the
    // viewport are important even if the element has not changed relative to its parent, and
    // the MutationObserver will not detect this.
    const [rectRef, headerRect] = useRect({ watchWindowResize: true })
    const tempRef = useForkRef(inViewRef, rectRef)
    const headerRef = useForkRef(tempRef, outerRef)

    return (
      <>
        <div
          className={className}
          style={style}
          aria-hidden
          ref={headerRef}
        >
          {children()}
        </div>
        <div
          className={className}
          css={styles.stickyHeader}
          style={{
            display: stickyHeaderEnabled && !inView ? 'block' : 'none',
            left: headerRect?.left,
            width: headerRect?.width,
            height: headerRect?.height,
            ...style,
          }}
          aria-hidden
        >
          {children()}
        </div>
      </>
    )
  },
)
