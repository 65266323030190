import { useParams } from 'react-router-dom'
import { getPage } from '@benkrejci/shared/dist/api/api'
import { Page } from '../Page'
import { DataLoader } from '../DataLoader'
import { useCachedFetchSingle } from '../../utility/api/useCachedFetch'
import { useSite } from '../../utility/api/useSite'

export const PageWithData = () => {
  const slug = useParams().slug ?? 'home'

  const siteState = useSite()

  const pageState = useCachedFetchSingle('page', 'slug', slug, {
    doLoad: slug !== undefined,
    load: getPage,
    getErrorMessage: () => 'Oh no! There was a problem loading that page 😭',
  })

  return (
    <DataLoader fetchState={[siteState, pageState]} showSpinner={false}>
      {([site, page], isLoading) => <Page isLoading={isLoading} slug={slug} site={site} page={page} />}
    </DataLoader>
  )
}
