import React from 'react'
import { useIntersectionObserverRef } from 'rooks'

/**
 * Hook to track when ref element is visible in viewport or not
 * @returns [isVisible, triggerRef] - where isVisible is true if element is visible
 *          and triggerRef should be passed as "ref" to element you want to watch for
 *          visibility
 */
export const useIsInView = (props: IntersectionObserverInit) => {
  const [isVisible, setVisible] = React.useState(false)
  const callback = React.useCallback(([entry]: IntersectionObserverEntry[]) => setVisible(entry?.isIntersecting), [])
  const [triggerRef] = useIntersectionObserverRef(callback, props)

  return [isVisible, triggerRef] as const
}
