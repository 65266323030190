import type { Attribute, Common } from '@strapi/strapi'
import { APIResponse, APIResponseCollection } from './types'

const get = async (uri: string, options: RequestInit = {}) => fetch(uri, {
    // mode: process.env.NODE_ENV === 'development' ? 'no-cors' : 'cors',
    ...options,
  })

export const fetchCollection = async <T extends Common.UID.ContentType>(uri: string) => {
  const response = await get(uri)

  if (!response.ok) {
    throw new Error(`Failed to fetch data: ${response.statusText}`)
  }

  return await response.json() as APIResponseCollection<T>
  // return flattenResponse(await response.json())
}

export const fetchSingle = async <T extends Common.UID.ContentType>(uri: string) => {
  const response = await get(uri)

  if (!response.ok) {
    throw new Error(`Failed to fetch data: ${response.statusText}`)
  }

  return await response.json() as APIResponse<T>
}

export const getDate = (date: Attribute.DateTimeValue | Attribute.DateValue) =>
  typeof(date) === 'string' ? new Date(date) : date
