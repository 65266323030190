import { useCachedFetchSingle } from '../../utility/api/useCachedFetch'
import { getSite } from '@benkrejci/shared/dist/api/api'
import { DataLoader } from '../DataLoader'
import { Page } from '../Page'
import { Alert } from '@mui/material'

export const DefaultRoute = () => {
  const siteState = useCachedFetchSingle(
    'site',
    'name',
    process.env.REACT_APP_SITE_NAME,
    {
      load: getSite,
      getErrorMessage: () => 'Oh no! There was a problem loading that site 😭',
    },
  )

  return (
    <DataLoader fetchState={[siteState]} showSpinner={false}>
      {([site], isLoading) => (
        <Page isLoading={isLoading} slug={''} site={site}>
          <Alert severity="error">Nothing to see here... 🤖</Alert>
        </Page>
      )}
    </DataLoader>
  )
}
