import React from 'react'
import { ApiLinks } from '@benkrejci/shared/dist/api/api'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Icon } from '../../icons/Icon'
import { OpenInNew } from '@mui/icons-material'
import { css } from '@emotion/react'
import { getLinkProps } from '../../utility/Link'

export const LinkList = ({ linkList }: { linkList: ApiLinks['links'] }) => (
  <List
    css={(theme) => css`
      & .Mui-ListItem {
        width: auto;
        & .MuiSvgIcon-root {
          color: ${theme.palette.primary.main};
        }
      }
    `}
  >
    {linkList?.map((link) => (
      <ListItemButton
        key={link.url}
        {...getLinkProps(link.url)}
      >
        {link.icon === undefined ? null : (
          <ListItemIcon>
            <Icon name={link.icon} />
          </ListItemIcon>
        )}
        <ListItemText primaryTypographyProps={{ variant: 'h5' }}>
          {link.label} <OpenInNew fontSize="small" />
        </ListItemText>
      </ListItemButton>
    ))}
  </List>
)
