import { ReactElement } from 'react'

import { Zoom } from '@mui/material'

import { ParallaxQueue } from '../../utility/ParallaxQueue'
import { QueuedParallaxShow } from '../../utility/QueuedParallaxShow'
import * as styles from './styles'

export const YearLine = ({
  parallaxQueue,
  enabled,
  fadeOut,
  fadeIn,
  className,
}: {
  parallaxQueue: ParallaxQueue
  enabled?: boolean
  fadeOut?: boolean
  fadeIn?: boolean
  animationDurationMs?: number
  className?: string
}): ReactElement => (
  <div css={styles.verticalLineCell} className={className} aria-hidden>
    <QueuedParallaxShow
      enabled={enabled}
      Transition={(props) => (
        <Zoom timeout={styles.YEAR_LINE_ANIMATION_DURATION_MS} {...props} />
      )}
      css={styles.verticalLineContainer}
      queue={parallaxQueue}
    >
      <div
        css={[
          styles.verticalLine,
          fadeOut ? styles.fadeOutLine : fadeIn ? styles.fadeInLine : '',
        ]}
      />
    </QueuedParallaxShow>
  </div>
)
