import { Navigate, useLocation } from "react-router-dom"
import { getPostUrl } from "@benkrejci/shared/dist/api/route"
import { useSite } from "../../utility/api/useSite"

export const VanityPathMatcher = () => {
  const { pathname, search, hash } = useLocation()
  const siteState = useSite()

  if (siteState === undefined) return null
  
  const source = `${pathname.replace(/^\//, '')}${search === '' ? '' : `?${search}`}${hash}`
  const matchingVanity = siteState.data?.vanityPaths?.find((vanity) =>
    source.startsWith(vanity.source),
  )
  if (matchingVanity) {
    return (
      <Navigate
        to={(() => {
          if (matchingVanity.destination != null) {
            return matchingVanity.destination
          }
          if (matchingVanity.page != null) {
            return `/${matchingVanity.page.slug}`
          }
          if (matchingVanity.post != null) {
            return getPostUrl(matchingVanity.post)
          }
          throw Error('Vanity path must have at least one of: destination, page, post')
        })()}
        replace={true}
      />
    )
  }
}