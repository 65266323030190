// source: https://gist.github.com/morajabi/523d7a642d8c0a2f71fcfa0d8b3d2846
import React from 'react'
import * as rooks from 'rooks'

export type CallbackRef<T extends HTMLElement | null = HTMLElement | null> = (
  node: T,
) => void

export const useRect = (
  { watchWindowResize = false }: { watchWindowResize: boolean } = {
    watchWindowResize: false,
  },
): [CallbackRef | null, DOMRect | null, () => void] => {
  const [domRect, setDomRect] = React.useState<DOMRect | null>(null)
  const [node, setNode] = React.useState<HTMLElement | null>(null)

  const update = rooks.useDebounce(() => {
    setDomRect(node ? node.getBoundingClientRect() : null)
  })

  React.useEffect(update, [update])

  const ref = React.useCallback((nodeElement: HTMLElement | null) => {
    setNode(nodeElement)
  }, [])

  const [resizeObserverRef] = rooks.useResizeObserverRef(update)
  rooks.useOnWindowResize(update, watchWindowResize)

  const forkedRef = rooks.useForkRef(ref, resizeObserverRef)

  return [forkedRef, domRect, update]
}
