"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPostUrl = exports.getIdOrSlug = exports.parseIdOrSlug = exports.getPageUrl = void 0;
const idRe = /^(?:.+:|)(\d+)$/;
const TITLE_SLUG_MAX_LENGTH = 32;
const getPageUrl = ({ slug }) => `/${slug === 'home' ? '' : encodeURIComponent(slug)}`;
exports.getPageUrl = getPageUrl;
/**
 * idOrSlug for post with id=12 and slug=a_slug can be:
 * - 12
 * - a_slug
 * - from_title:12
 */
const parseIdOrSlug = (idOrSlug) => {
    const idMatch = idOrSlug.match(idRe);
    if (idMatch) {
        return { id: parseInt(idMatch[1]), slug: undefined };
    }
    return { id: undefined, slug: idOrSlug };
};
exports.parseIdOrSlug = parseIdOrSlug;
const getIdOrSlug = (post) => {
    if (post.slug != null) {
        return post.slug;
    }
    return `${post.title.replace(/[^a-zA-Z0-9_-]+/g, '-').slice(0, TITLE_SLUG_MAX_LENGTH)}:${post.id}`;
};
exports.getIdOrSlug = getIdOrSlug;
const getPostUrl = (post) => `/post/${(0, exports.getIdOrSlug)(post)}`;
exports.getPostUrl = getPostUrl;
