import { ApiGallery } from '@benkrejci/shared/src/api/api'
import { Grid } from '@mui/material'
import { GalleryChild, GalleryItem } from './GalleryChild'
import { getGridPropsFromSize, getResponsiveSpace } from '../../../style/global'

export const GalleryGrid = ({
  items,
}: {
  widget: ApiGallery
  items: GalleryItem[]
}) => (
  <Grid container spacing={getResponsiveSpace(0.5)}>
    {items.map((item) => (
      <Grid item key={item.hash} {...getGridPropsFromSize('medium')}>
        <GalleryChild item={item} />
      </Grid>
    ))}
  </Grid>
)
