import React from 'react'
import deepEqual from 'fast-deep-equal'

export const useDeepMemo = <TKey, TValue>(
  memoFn: () => TValue,
  key: TKey
): TValue => {
  const ref = React.useRef<{ key: TKey; value: TValue }>();

  if (!ref.current || !deepEqual(key, ref.current.key)) {
    ref.current = { key, value: memoFn() };
  }

  return ref.current.value;
}
