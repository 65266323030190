import { ApiGallery } from '@benkrejci/shared/src/api/api'
import { useRect } from '../../../utility/useRect'
import { css, useTheme } from '@emotion/react'
import React from 'react'
import { GalleryChild, GalleryItem } from './GalleryChild'

const IDEAL_SIZE_PX = 400

type RowWithAspect = (GalleryItem & { aspect: number })[]

export const GalleryMasonry = ({
  items,
}: {
  widget: ApiGallery
  items: GalleryItem[]
}) => {
  const [wrapperRef, wrapperRect] = useRect() ?? {}
  const { width: wrapperWidth } = wrapperRect ?? {}
  const [containerRef, containerRect] = useRect() ?? {}
  const containerHeight = containerRect?.height ?? 0
  const theme = useTheme()
  const spacingPx = parseInt(theme.spacing(1), 10)
  const [containerWidth, itemsWithDims] = React.useMemo(() => {
    if (wrapperWidth === undefined) return [wrapperWidth, []]

    const rows: RowWithAspect[] = [[]]
    let currentRow = rows[0]
    let currentRowWidthPx = 0
    items?.forEach((item) => {
      const largestDim = (item.width ?? 1) >= (item.height ?? 1) ? 'width' : 'height'
      const aspect = (item.width ?? 1) / (item.height ?? 1)
      const initialWidth = largestDim === 'width' ? IDEAL_SIZE_PX : aspect * IDEAL_SIZE_PX
      if (currentRowWidthPx + initialWidth > wrapperWidth) {
        currentRow = []
        rows.push(currentRow)
        currentRowWidthPx = 0
      }
      currentRowWidthPx += initialWidth
      currentRow.push({ ...item, aspect })
    })

    return [
      wrapperWidth,
      rows
        .map((row, index) => {
          const fakeRowAspects: number[] = []
          while (true) {
            const aspectSum =
              row.reduce((acc, item) => acc + item.aspect, 0) +
              fakeRowAspects.reduce((acc, aspect) => acc + aspect, 0)
            const numSpaces = row.length + fakeRowAspects.length - 1
            const rowHeight = (wrapperWidth - numSpaces * spacingPx) / aspectSum
            const rowWithDims = row.map((item) => ({
              heightPx: rowHeight,
              widthPx: rowHeight * item.aspect,
              item,
            }))
            if (
              index < rows.length - 1 ||
              !rowWithDims.some(
                (cell) => Math.max(cell.widthPx, cell.heightPx) > IDEAL_SIZE_PX * 1.5,
              )
            ) {
              return rowWithDims
            }
            fakeRowAspects.push(row[fakeRowAspects.length % row.length].aspect)
          }
        })
        .flat(),
    ]
  }, [items, wrapperWidth, spacingPx])

  return (
    <div
      ref={wrapperRef}
      css={css`
        position: relative;
        // See note below about absolute positioning
        height: ${containerHeight}px;
      `}
    >
      <div
        css={(theme) => css`
          // Use position absolute so that the children's width cannot cause the viewport width
          // to change, which could otherwise cause the gallery to infinitely re-render as it
          // expands (due to imperfect math or cumulative floating point errors).
          // This is why we need to manually set the parent height above.
          position: absolute;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: ${spacingPx}px;
          width: ${containerWidth}px;
        `}
        ref={containerRef}
      >
        {itemsWithDims.map((media) => (
          <GalleryChild
            item={media.item}
            heightPx={media.heightPx}
            widthPx={media.widthPx}
            css={css`
              flex: 0 1 auto;
            `}
            key={media.item.hash}
          />
        ))}
      </div>
    </div>
  )
}
