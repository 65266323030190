import { forwardRef, ReactElement } from 'react'

import * as styles from './styles'

export const CategoryLine = forwardRef<
  HTMLDivElement,
  {
    color: string
    className?: string
  }
>(
  ({ color, className }, ref): ReactElement => (
    <div
      css={[styles.verticalLineCell, styles.categoryLineCell]}
      className={className}
      aria-hidden
      ref={ref}
    >
      <div
        css={[styles.verticalLine, styles.verticalLineCap]}
        style={{
          background: `linear-gradient(180deg, transparent 0%, ${color} 100%)`,
        }}
      />
      <div css={styles.verticalLine} style={{ backgroundColor: color }} />
      <div
        css={[styles.verticalLine, styles.verticalLineCap]}
        style={{
          background: `linear-gradient(180deg, ${color} 0%, transparent 100%)`,
        }}
      />
    </div>
  ),
)
