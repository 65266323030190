import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export default function Thangs(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 80 80" {...props}>
      <path d="M48.42,46.71c0-4.66,0-9.32,0-13.98,0-1.13-.04-1.16-1.16-1.16-2.78,0-5.56-.04-8.35,0-5.42,.09-9.68,4.11-9.74,9.34-.08,6.61-.18,13.23,.03,19.83,.22,7.16,7.43,11.16,13.65,8.34,3.71-1.69,5.51-4.78,5.55-8.82,.05-4.51,.01-9.03,.01-13.55Zm-9.66,18.45c-2.62,0-4.72-2.11-4.74-4.76-.02-2.58,2.15-4.76,4.75-4.77,2.63-.01,4.83,2.2,4.81,4.84-.02,2.57-2.2,4.7-4.82,4.69Zm.07-19.19c-2.6,.02-4.77-2.1-4.82-4.71-.05-2.61,2.16-4.85,4.8-4.85,2.53,0,4.78,2.24,4.78,4.78,0,2.58-2.18,4.77-4.75,4.79Z" />
      <path d="M48.41,19.72c-.01-6.09-5.09-9.86-9.76-9.72-6.39,.2-12.79,.04-19.18,.05-.65,0-1.31,0-1.94,.14-4.38,.9-8.45,5.07-7.35,11.27,.78,4.44,4.69,7.65,9.21,7.68,4.59,.03,9.17,0,13.76,0h0c4.7,0,9.39,0,14.09,0,1.17,0,1.18,0,1.18-1.2,0-2.74,0-5.49,0-8.23Zm-28.84,4.63c-2.61,0-4.73-2.12-4.76-4.76-.02-2.63,2.07-4.76,4.71-4.78,2.63-.02,4.81,2.09,4.84,4.68,.03,2.65-2.15,4.85-4.79,4.86Zm19.09,0c-2.59-.06-4.69-2.29-4.63-4.89,.07-2.68,2.26-4.73,4.96-4.64,2.55,.08,4.62,2.25,4.59,4.81-.03,2.64-2.27,4.79-4.92,4.72Z" />
      <path d="M69.8,17.56c-.78-3.82-4.58-7.73-9.68-7.54-4.5,.17-8.67,3.55-9.09,8.03-.32,3.4-.15,6.85-.21,10.28-.01,.69,.38,.84,.97,.83,1.52-.01,3.04,0,4.55,0h0c1.45,0,2.89,.02,4.34-.01,5.95-.15,10.31-5.76,9.12-11.59Zm-9.46,6.79c-2.59-.01-4.7-2.17-4.71-4.8,0-2.66,2.05-4.71,4.74-4.72,2.75-.01,4.76,1.95,4.78,4.64,.02,2.82-2.02,4.89-4.81,4.88Z" />
    </SvgIcon>
  )
}
