import React from 'react'
import { css, Theme } from '@emotion/react'
import { ApiEpisode, ApiPostPodcast } from '@benkrejci/shared/dist/api/api'
import { Box, Grid, List, ListItem, Paper, Typography } from '@mui/material'
import { WrapIf } from '../../utility/WrapIf'
import { Media } from '../Media'
import { Explicit, OpenInNew } from '@mui/icons-material'

import * as globalStyles from '../../style/global'
import { RichText } from './RichText'
import { Link } from '../../utility/Link'

export const PostPodcast = ({ post }: { post: ApiPostPodcast }) => (
  <Grid container spacing={globalStyles.getResponsiveSpace()}>
    <Grid item xs={12} sm={5} md={4}>
      <Paper>
        <WrapIf
          if={post.postType.url}
          wrap={(children) => <Link href={post.postType.url!}>{children}</Link>}
        >
          {() => <Media file={post.cover!} />}
        </WrapIf>
      </Paper>
    </Grid>
    <Grid item xs={12} sm={7} md={8}>
      <Box>
        <Typography variant="h2">
          <WrapIf
            if={post.postType.url}
            wrap={(children) => (
              <>
                <Link
                  href={post.postType.url!}
                  color="textPrimary"
                  css={globalStyles.linkNoDecoration}
                >
                  {children}{' '}
                  {post.postType.explicit ? (
                    <Explicit css={globalStyles.inlineIcon} />
                  ) : (
                    ''
                  )}{' '}
                  <OpenInNew
                    titleAccess="opens in new window"
                    css={globalStyles.inlineIcon}
                  />
                </Link>
              </>
            )}
          >
            {() => post.title}
          </WrapIf>
        </Typography>
        <Typography component="p" variant="subtitle1">
          {post.postType.company}
        </Typography>
        <Box mt={4}>
          <RichText topHeadingLevel={3} topHeadingVariantLevel={5}>
            {post.description}
          </RichText>
        </Box>
      </Box>
    </Grid>
    {post.postType.episodes?.length ? (
      <Grid item xs={12}>
        <Box>
          <Typography component="h3" variant="h4" gutterBottom>
            Notable Contributions
          </Typography>
          <Paper>
            <List disablePadding>
              {post.postType.episodes.map((episode, projectIndex) => (
                <ListItem
                  key={episode.title}
                  divider={projectIndex < post.postType.episodes!.length - 1}
                  disableGutters
                  css={css`
                    padding: 0;
                  `}
                >
                  <PodcastEpisode post={post} episode={episode} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Box>
      </Grid>
    ) : null}
  </Grid>
)

export const PodcastEpisode = ({
  post,
  episode,
}: {
  post: ApiPostPodcast
  episode: ApiEpisode
}) => (
  <div
    css={css`
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    `}
  >
    <ItemLink
      episode={episode}
      css={[
        lineItemCss,
        css`
          flex-grow: 1;
        `,
      ]}
    >
      <Typography component="h4" variant="h5">
        {episode.title}{' '}
        {post.postType.explicit ? <Explicit css={globalStyles.inlineIcon} /> : ''}
      </Typography>
      {episode.description ? (
        <RichText topHeadingLevel={5} topHeadingVariantLevel={6}>
          {episode.description}
        </RichText>
      ) : (
        ''
      )}
    </ItemLink>
    {episode.url ? (
      <ItemLink episode={episode} css={lineItemCss}>
        <OpenInNew />
      </ItemLink>
    ) : (
      ''
    )}
    {episode.audio ? (
      <div
        css={[
          lineItemCss,
          (theme) => css`
            flex-grow: 1;
            flex-wrap: nowrap;
            & .MuiPaper-root {
              background-color: ${theme.palette.background.default};
            }
            & .MuiGrid-root {
              align-items: center;
              & .MuiSvgIcon-root {
                display: block;
              }
            }
          `,
        ]}
      >
        <AudioPlayer
          src={episode.audio.data.attributes.url}
          download={false}
          autoplay={false}
          loop={false}
        />
      </div>
    ) : (
      ''
    )}
  </div>
)

const ItemLink = ({
  episode,
  children,
  className,
}: {
  episode: ApiEpisode
  children: React.ReactNode
  className?: string
}) => (
  <WrapIf
    if={episode.url}
    wrap={(innerChildren) => (
      <Link
        href={episode.url!}
        color="inherit"
        className={className}
        css={globalStyles.linkNoDecoration}
      >
        {innerChildren}
      </Link>
    )}
    elseWrap={(innerChildren) => <div className={className}>{innerChildren}</div>}
  >
    {() => children}
  </WrapIf>
)

const AudioPlayer = ({
  src,
  download,
  autoplay,
  loop,
}: {
  src: string
  download?: boolean
  autoplay?: boolean
  loop?: boolean
}) => {
  throw Error('Not implemented')
}

const lineItemCss = (theme: Theme) => css`
  padding: ${theme.spacing(2)};
  flex-grow: 0;
`
