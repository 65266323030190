import React from 'react'

import { Box, useTheme } from '@mui/material'

import { Timeline } from '../timeline/Timeline'
import { RichText } from './RichText'
import { ApiTimeline } from '@benkrejci/shared/dist/api/api'
import { IconName } from '../../icons/Icon'

export const TimelineWidget = ({
  widget,
}: {
  widget: ApiTimeline
}) => {
  const theme = useTheme()

  const categoryColors = [
    //theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.tertiary.main,
    theme.palette.quarternary.main,
    theme.palette.grey[400],
  ].reverse()
  const categories: Array<{
    color: string
    contrastColor: string
    name: string
  }> = []
  if (widget.events === undefined) return null
  const events = widget.events
    .map((event) => ({
      ...event,
      start: new Date(event.start.toString()),
      icon: event.icon as IconName,
    }))
    .sort((event1, event2) => event1.start.getTime() - event2.start.getTime())
    .map((event) => {
      let category = categories.find((cat) => cat.name === event.category)
      if (!category) {
        const categoryColor = categoryColors.pop()
        category = {
          name: event.category,
          color: categoryColor!,
          contrastColor: theme.palette.getContrastText(categoryColor!),
        }
        categories.push(category)
      }
      return {
        ...event,
        category,
      }
    })

  return (
    <Box>
      <RichText>{widget.description}</RichText>
      <Timeline events={events} categories={categories} />
    </Box>
  )
}
