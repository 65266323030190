import React from 'react'
import { LinkProps, Link as MuiLink } from '@mui/material'
import { ExternalLink } from './ExternalLink'
import { InternalLink } from './InternalLink'

const EXTERNAL_HREF_REGEX = /^[a-z]+:\/\//

export const getLinkProps = (
  href: string,
  opts?: { preventScrollReset?: boolean },
): {
  href: string
  target?: string
  component: typeof ExternalLink | typeof InternalLink
} => {
  const isExternal = EXTERNAL_HREF_REGEX.test(href)
  return {
    href,
    target: isExternal ? '_blank' : undefined,
    component: React.forwardRef((props: any, ref) => isExternal ?
    (
      <ExternalLink {...{ ...props }} ref={ref} />
    ) : (
      <InternalLink {...{ ...props, ...opts }} ref={ref} />
    )),
  }
}

export const getButtonLinkProps = (
  href: string,
  opts?: { preventScrollReset?: boolean },
): {
  href: string
  target?: string
  LinkComponent: typeof ExternalLink | typeof InternalLink
} => {
  const { target, component } = getLinkProps(href, opts)
  return {
    href,
    target,
    LinkComponent: component,
  }
}

export const Link = ({
  preventScrollReset,
  ...props
}: Omit<LinkProps, 'component'> & { href: string; preventScrollReset?: boolean }) => (
  <MuiLink {...getLinkProps(props.href, { preventScrollReset })} {...props} />
)
