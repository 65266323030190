import React from 'react'

export const WrapIf = React.forwardRef(
  (
    {
      children,
      if: ifProp,
      wrap,
      elseWrap,
    }: {
      if: any
      wrap: (children: React.ReactNode, ref: React.ForwardedRef<any>) => React.ReactNode
      elseWrap?: (
        children: React.ReactNode,
        ref: React.ForwardedRef<any>,
      ) => React.ReactNode
      children: (ref?: React.ForwardedRef<any>) => React.ReactNode
    },
    ref,
  ) =>
    ifProp ? (
      <>{wrap(children(), ref)}</>
    ) : elseWrap ? (
      <>{elseWrap(children(), ref)}</>
    ) : (
      children(ref)
    ),
)
