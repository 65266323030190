import React from 'react'
import { useDeepCompareEffect } from 'rooks'
import deepEqual from 'fast-deep-equal'
import { entries } from '@benkrejci/shared/src/utility'
import { getDebugParams } from '../getDebugParams'

export interface FetchState<T> {
  isLoading: boolean
  error?: string
  data?: T
}

const localStorageKey = `cachedFetch.${process.env.REACT_APP_SITE_NAME}`
const cacheVersion = 3

const createStore = () => {
  localStorage.clear()
  return { version: cacheVersion, cache: {} }
}

const dataStore: {
  version: number
  cache: Record<
    string,
    { lastFetch?: number; promise?: Promise<unknown>; data?: unknown }
  >
} = (() => {
  if (getDebugParams().disableCache) return createStore()
  try {
    const json = localStorage.getItem(localStorageKey)
    if (json === null) return createStore()
    const data = JSON.parse(json)
    if (data.version !== cacheVersion) return createStore()
    return data
  } catch (e) {
    return createStore()
  }
})()

const cache = dataStore.cache

const setLocalStorage = () => {
  if (getDebugParams().disableCache) return
  const storeWithoutPromises = {
    ...dataStore,
    cache: Object.fromEntries(
      Object.entries(cache).map(([key, { data }]) => [key, { data }]),
    ),
  }
  try {
    localStorage.setItem(localStorageKey, JSON.stringify(storeWithoutPromises))
  } catch (e) {
    console.error(e)
  }
}

type Keys<TParams> = Array<{ param: keyof TParams; key: string }>

const getDataFromStore = <TData extends unknown, TParams extends object>(
  keys: Keys<TParams>,
): FetchState<TData> => {
  for (const { key } of keys) {
    if (cache[key]?.data !== undefined) {
      return { data: cache[key]?.data as TData, isLoading: false }
    }
  }
  return {
    data: undefined,
    isLoading: true,
  }
}

const setDataIfChanged = <TData extends object>(
  oldData: TData | undefined,
  newData: TData,
  setData: (data: TData) => void,
) => {
  if (!deepEqual(oldData, newData)) {
    setData(newData)
  }
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))


const NUM_RETRIES = 4
const retry =
  <TData, TParams>(load: (params: TParams) => Promise<TData>) =>
  async (params: TParams) => {
    for (let i = 0; i < NUM_RETRIES; i++) {
      try {
        return await load(params)
      } catch (e) {
        if (i === NUM_RETRIES - 1) throw e
        // Retry right away the first time, then back off
        await sleep(2000 * i)
      }
    }
    throw Error('Unreachable')
  }

const getCacheKey = (typeId: string, paramName: string, paramValue: unknown) =>
  `${typeId}.${paramName}:${paramValue}`

/**
 * Use this instead of useCachedFetch when you only need to use one param.
 * This saves you from having to define `getParams` and usually explicit Generic
 * types as well.
 */
export const useCachedFetchSingle = <
  TData extends unknown,
  TParamValue extends string | number,
>(
  typeId: string,
  paramName: string,
  paramValue: TParamValue,
  {
    load,
    ...props
  }: {
    load: (param: TParamValue) => Promise<TData>
    getErrorMessage: (e: Error) => string
    doLoad?: boolean
    refetch?: boolean
  },
) =>
  useCachedFetch({
    typeId,
    params: { [paramName]: paramValue },
    getParams: () => ({ [paramName]: paramValue }),
    load: () => load(paramValue),
    ...props,
  })

/**
 * Use a local variable and localStorage to cache the result of a fetch.
 *
 * @param props
 *   - typeId {string} - A unique identifier for this data type (e.g. 'post')
 *   - params {Record<string, any>}
 *       - Fetch is triggered when any of the values change
 *       - multiple keys can be used if the same data may be referred to by multiple
 *         different params. In this case, all params will refer to the same data
 *   - getParams {Function} - Takes the resolved return of the load function and
 *       MUST return all params that can be used to lookup (and cache) this data
 *   - load {Function} - Takes params and returns a promise that resolves to the data
 *   - getErrorMessage {Function} - Takes an error and returns a string in case of error
 *   - doLoad {boolean} - If false, no fetch will be triggered
 *   - refetch {boolean} - If false, data will not be re-fetched on mount if it is
 *       already cached. Otherwise, a fetch will always be triggered on mount, though if
 *       cache exists, the loading state will remain false and the data will be updated
 *       on return (if it has changed)
 * @returns {FetchState} - With isLoading, error, and data
 */
export const useCachedFetch = <
  TData extends unknown,
  TParams extends Record<string, string | number>,
>({
  typeId,
  params,
  getParams,
  load: loadRaw,
  getErrorMessage,
  doLoad = true,
  refetch = true,
}: {
  typeId: string
  params: Partial<TParams>
  getParams: (data: TData) => TParams
  load: (params: Partial<TParams>) => Promise<TData>
  getErrorMessage: (e: Error) => string
  doLoad?: boolean
  refetch?: boolean
}) => {
  const apiDelayMs = getDebugParams().apiDelayMs
  const loadDelayed =
    apiDelayMs === 0
      ? loadRaw
      : async (params: Partial<TParams>) => {
          await sleep(apiDelayMs)
          return loadRaw(params)
        }
  const load = retry(loadDelayed)
  const keys = React.useMemo(
    () =>
      entries(params).map(([name, value]) => ({
        param: name,
        key: getCacheKey(typeId, String(name), value),
      })) as Keys<TParams>,
    [typeId, params],
  )
  const [fetchState, setFetchState] = React.useState<FetchState<TData>>(() =>
    getDataFromStore(keys),
  )

  useDeepCompareEffect(() => {
    let isUnmounted = false

    setDataIfChanged(fetchState, getDataFromStore(keys), setFetchState)
    ;(async () => {
      if (
        doLoad &&
        (refetch || keys.some(({ key }) => cache[key]?.data === undefined))
      ) {
        try {
          if (keys.some(({ key }) => cache[key]?.promise === undefined)) {
            const promise = load(params)
            keys.forEach(({ key }) => {
              cache[key] = { promise, data: cache[key]?.data }
            })
            const data = { data: await promise }
            entries(getParams(data.data)).forEach(([name, value]) => {
              if (value === undefined) return
              cache[getCacheKey(typeId, String(name), value)] = data
            })

            setTimeout(setLocalStorage)

            if (isUnmounted) return
          } else {
            await Promise.all(keys.map(({ key }) => cache[key].promise))
            if (isUnmounted) return
          }
        } catch (e) {
          console.error(e)
          setFetchState({
            isLoading: false,
            data: getDataFromStore<TData, TParams>(keys).data,
            error: getErrorMessage(e as Error),
          })
          return
        }

        setDataIfChanged(fetchState, getDataFromStore(keys), setFetchState)
      }
    })()

    return () => {
      isUnmounted = true
    }
  }, [params, doLoad])

  return fetchState
}
