import React from 'react'

import { Grow } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'

import { ParallaxQueue } from './ParallaxQueue'

export const QueuedParallaxShow = <T extends TransitionProps & { children: React.ReactElement }>({
  children,
  queue,
  enabled = true,
  Transition = (props) => <Grow {...props} />,
  ...props
}: {
  children: T['children']
  queue: ParallaxQueue  
  enabled?: boolean
  Transition?: (props: T) => React.ReactElement
} & React.HTMLProps<HTMLDivElement>) => {
  const [animateIn, setAnimateIn] = React.useState(false)

  const ref = React.useCallback((node: HTMLDivElement) => {
    if (node) {
      queue.add(setAnimateIn, node)
    } else {
      queue.remove(setAnimateIn)
    }
  }, [])

  return (
    <div ref={ref} {...props}>
      <Transition {...{ in: enabled && animateIn} as T}>{children}</Transition>
    </div>
  )
}
