import React from 'react'

interface ExternalLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  href: string
  children: React.ReactNode
}

export const ExternalLink = React.forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  (props, ref) => {
    const { onClick, children, ...rest } = props

    const wrappedOnClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
      if (onClick) {
        onClick(e)
      }
    }

    return (
      <a ref={ref} {...rest} onClick={wrappedOnClick}>
        {children}
      </a>
    )
  },
)
