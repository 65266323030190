import React from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

export default function Drum(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M 18.46875 3.0625 C 18.31875 3.0375 18.15 3.0875 18 3.1875 L 9 8.59375 L 9 8.90625 C 9.1 9.00625 9.2125 9.09375 9.3125 9.09375 L 13.59375 6.8125 C 14.591222 7.0063824 15.544277 7.2439255 16.3125 7.5 L 18 6.6875 C 17.346486 6.2790541 16.553426 5.9624835 15.6875 5.6875 L 18.5 4.1875 C 18.8 3.9875 19.0125 3.70625 18.8125 3.40625 C 18.7625 3.20625 18.61875 3.0875 18.46875 3.0625 z M 11 5 C 6 5 2 7 2 9.5 L 2 16.5 C 2 17.8 3.2 19.0125 5 19.8125 L 5 12.84375 C 5.5968316 13.111754 6.2732562 13.350504 7 13.53125 L 7 20.5 C 8.2 20.8 9.6 21 11 21 C 12.4 21 13.8 20.8 15 20.5 L 15 13.53125 C 15.726744 13.350504 16.403168 13.111754 17 12.84375 L 17 19.8125 C 18.8 19.0125 20 17.8 20 16.5 L 20 9.5 C 20 9.25 19.946289 9 19.84375 8.75 L 21.3125 8.3125 C 21.7125 8.2125 22.00625 7.8 21.90625 7.5 C 21.80625 7.2 21.4 7.0875 21 7.1875 L 10.6875 11 C 10.5875 11 10.59375 11.0875 10.59375 11.1875 C 10.59375 11.2875 10.7125 11.40625 10.8125 11.40625 L 18.3125 9.1875 C 18.3625 9.3 18.40625 9.4 18.40625 9.5 C 18.40625 10.7 15.4 12.40625 11 12.40625 C 6.6 12.40625 3.59375 10.7 3.59375 9.5 C 3.59375 8.4 6.3125 6.79375 10.3125 6.59375 L 12.1875 5 L 11 5 z M 9.375 13.90625 C 9.9061565 13.954406 10.439483 14 11 14 C 11.560517 14 12.093844 13.954406 12.625 13.90625 C 12.089749 13.961385 11.547193 14 11 14 C 10.452807 14 9.9102509 13.961385 9.375 13.90625 z" />
    </SvgIcon>
  )
}
